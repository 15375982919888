<template>
  <div class="clinics-view">

    <v-row>
      <div class="add-clinic mr-3">
        <CreateFunctionView />
        <!-- New component is required here -->
        <v-dialog v-model="openClinicModal" max-width="600" persistent>
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn class="text-none font-weight-regular" prepend-icon="mdi-hospital-building" text="Add Clinic"
              variant="tonal" v-bind="activatorProps"></v-btn>
          </template>
          <v-card prepend-icon="mdi-hospital-building" title="Clinic Details">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Name" required v-model="newClinic.name"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Address" v-model="newClinic.address"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Phone" v-model="newClinic.phoneNumbers"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Forward To Numbers" v-model="newClinic.forwardsToNumber"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Seat1s" v-model="newClinic.seats"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Admins" v-model="newClinic.admins"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Clinicians" v-model="newClinic.clinicians"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field hint="comma separated" v-model="newClinic.domains" label="Domains"></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-card prepend-icon="mdi-android" title="Agent" width="100%">
                  <v-card-text>
                    <v-row dense>
                      <v-col>
                        <v-checkbox label="Enable Agent" v-model="newClinic.agentEnabled"></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-checkbox label="Recording Enabled" v-model="newClinic.recordingEnabled"></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense="true">
                      <v-col>
                        <v-textarea label="Agent Greeting" auto-grow="true" rows="2"
                          v-model="newClinic.agentGreeting"></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="Request Types" v-model="newClinic.requestTypes"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">

                        <v-select :list-props="{ maxWidth: '550px' }" v-model="newClinic.functionsEnabled"
                          :items="agentCallFunctions" item-title="name" :item-props="agentFunctionDescription"
                          item-value="name" label="Available Functions" multiple chips
                          hint="Select the agent functions to enable for this clinic" persistent-hint></v-select>

                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">

                        <v-select :list-props="{ maxWidth: '550px' }" v-model="newClinic.tasksEnabled"
                          :items="agentTaskFunctions" item-title="name" :item-props="agentFunctionDescription"
                          item-value="name" label="Available Tasks" multiple chips
                          hint="Select the agent tasks to enable for this clinic" persistent-hint></v-select>

                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-card>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text="Close" variant="plain" @click="closeWithoutSaving"></v-btn>

              <v-btn color="primary" text="Save" variant="tonal" @click="closeAndSave"></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- list of clinics -->
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Clinics</v-card-title>
          <v-table hover>
            <tbody>
              <tr v-for="clinic in clinics" :key="clinic.id" @click="selectClinic(clinic)"
                :class="{ 'clinic-item': true, 'selected-row': selectedClinic?.id === clinic.id }">
                <td>{{ clinic.name }}</td>
                <td><v-btn @click="showDeleteClinic = true" prepend-icon="mdi-delete" elevation="0"></v-btn>
                </td>
                <v-dialog v-model="showDeleteClinic" max-width="600" :scrim="false">
                  <v-card prepend-icon="mdi-delete"
                    :title="`Do you want to delete the clinic: ${selectedClinic?.name}?`">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text="Cancel" variant="plain" @click="showDeleteClinic = false"></v-btn>
                      <v-btn text="Delete" variant="plain" @click="deleteClinic(selectedClinic.id)"></v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </tr>
            </tbody>

          </v-table>
        </v-card>
      </v-col>
      <!-- Clinic Details -->
      <v-col cols="12" md="8">
        <v-card v-if="selectedClinic" class="clinic-details">
          <div class="clinic-details-title">
            <v-btn variant="tonal" v-bind="activatorProps" @click="editClinic">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-card-title>{{ selectedClinic.name }}</v-card-title>
          </div>

          <v-card-text>
            <v-table>
              <tbody>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">ID</td>
                  <td>{{ selectedClinic.id }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Address</td>
                  <td>{{ selectedClinic.address }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Phone Numbers</td>
                  <td>{{ selectedClinic.phoneNumbers }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Forward To Numbers</td>

                  <td>{{ selectedClinic.forwardsToNumber }}</td>


                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Admins</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="admin in selectedClinic.admins" :key="admin" size="small" color="primary">
                        {{ admin }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Clinicians</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="clinician in selectedClinic.clinicians" :key="clinician" size="small"
                        color="success">
                        {{ clinician }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Domains</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="domain in selectedClinic.domains" :key="domain" size="small" color="info">
                        {{ domain }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Seats</td>
                  <td>{{ selectedClinic.seats }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Templates</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="template in selectedClinic.templates" :key="template.name" size="small"
                        color="warning">
                        {{ template.name }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Enabled </td>
                  <td>{{ selectedClinic.agentEnabled }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Recording Enabled </td>
                  <td>{{ selectedClinic.recordingEnabled }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Prompt </td>
                  <td>
                    <v-btn text @click="openPrompt = true">Open Agent Prompt</v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Greeting </td>
                  <td>{{ selectedClinic.agentGreeting }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Request Types </td>
                  <td>{{ selectedClinic.requestTypes }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Enabled Functions</td>
                  <td>
                    <v-chip-group>
                      <v-tooltip v-for="functionName in selectedClinic.functionsEnabled" :key="functionName"
                        :text="getFunctionDescription(functionName)">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" size="small" color="secondary">
                            {{ functionName }}
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Enabled Tasks</td>
                  <td>
                    <v-chip-group>
                      <v-tooltip v-for="taskName in selectedClinic.tasksEnabled" :key="taskName"
                        :text="getTasksDescription(taskName)">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" size="small" color="secondary">
                            {{ taskName }}
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Summary Prompt</td>
                  <td>
                    <v-btn text @click="openSummaryPromptDialog">Open Summary Prompt</v-btn>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="text-center pa-8">
            <v-icon icon="mdi-hospital-building" size="64" color="grey-lighten-1" />
            <div class="text-h6 mt-4">Select a clinic to view details</div>
          </v-card-text>
        </v-card>
        <ClinicConversations v-if="selectedClinic" :clinic-id="selectedClinic.id" class="mt-4" />
      </v-col>
    </v-row>
  </div>
  <v-dialog v-model="openPrompt" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="closePromptDialog"></v-btn>

        <v-toolbar-title prepend-icon="mdi-android">Agent Prompt</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn prepend-icon="mdi-pencil" text="Edit" variant="text" @click="isEditing = !isEditing"
            v-if="selectedVersion === 'current'"></v-btn>
          <v-spacer></v-spacer>
          <v-btn text="Rollback" color="warning" variant="tonal"
            :disabled="selectedVersion === 'current' || loadingAction" :loading="loadingAction"
            @click="handleRollback"></v-btn>
          <v-btn text="Save New Version" color="primary" variant="tonal" :loading="loadingAction"
            :disabled="!isEditing || selectedVersion !== 'current'" @click="handleSaveNewVersion"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select v-model="selectedVersion" :items="promptVersions" item-title="label" item-value="versionId"
              label="Select Version" :loading="loadingVersions" @update:model-value="handleVersionChange"></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea style="overflow-y: auto;" auto-grow="true" :readonly="!isEditing" v-model="displayedPrompt"
              label="Agent Prompt"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openSummaryPrompt" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="openSummaryPrompt = false"></v-btn>

        <v-toolbar-title prepend-icon="mdi-android">Summary Prompt</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea style="overflow-y: auto;" auto-grow="true" :readonly="!isEditing"
              v-model="selectedClinic.agentSummaryPrompt" label="Summary Prompt"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- TODO: Summary Prompt Dialog needs to be a new component! -->
  <v-dialog v-model="openSummaryPrompt" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="closeSummaryPromptDialog"></v-btn>
        <v-toolbar-title prepend-icon="mdi-android">Summary Prompt</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn prepend-icon="mdi-pencil" text="Edit" variant="text"
            @click="isEditingSummaryPrompt = !isEditingSummaryPrompt"
            v-if="selectedSummaryVersion === 'current'"></v-btn>
          <v-spacer></v-spacer>
          <v-btn text="Rollback" color="warning" variant="tonal"
            :disabled="selectedSummaryVersion === 'current' || loadingSummaryAction" :loading="loadingSummaryAction"
            @click="handleSummaryRollback"></v-btn>
          <v-btn text="Save New Version" color="primary" variant="tonal" :loading="loadingSummaryAction"
            :disabled="!isEditingSummaryPrompt || selectedSummaryVersion !== 'current'"
            @click="handleSaveSummaryVersion"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select v-model="selectedSummaryVersion" :items="summaryPromptVersions" item-title="label"
              item-value="versionId" label="Select Version" :loading="loadingSummaryVersions"
              @update:model-value="handleSummaryVersionChange"></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea style="overflow-y: auto;" auto-grow="true" :readonly="!isEditingSummaryPrompt"
              v-model="displayedSummaryPrompt" label="Summary Prompt"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { db } from '@/firebase';
import axios from 'axios'; // Make sure axios is installed and imported
import ClinicConversations from '@/components/ClinicConversations.vue';
import CreateFunctionView from '@/components/CreateFunctionView.vue';
import { fetchAgentFunctions, agentFunctions, agentTaskFunctions, agentCallFunctions } from '@/composables/useAgentFunctions';

const summaryPromptVersions = ref([]);
const selectedSummaryVersion = ref(null);
const loadingSummaryVersions = ref(false);
const displayedSummaryPrompt = ref('');
const isEditingSummaryPrompt = ref(false);
const loadingSummaryAction = ref(false);

const promptVersions = ref([]);
const selectedVersion = ref(null);
const loadingVersions = ref(false);
const displayedPrompt = ref('');

const isEditing = ref(false);
const loadingAction = ref(false);

const clinics = ref([]);
const selectedClinic = ref(null);
const openClinicModal = ref(false);
const openPrompt = ref(false);
const openSummaryPrompt = ref(false);
const editing = ref(false);
const showDeleteClinic = ref(false);
const newClinic = ref({
  name: '',
  address: '',
  phoneNumbers: '',
  forwardsToNumber: '',
  seats: 0,
  admins: '',
  clinicians: '',
  domains: '',
  agentEnabled: false,
  recordingEnabled: false,
  agentPrompt: '',
  agentGreeting: '',
  requestTypes: '',
  agentSummaryPrompt: '',
  functionsEnabled: [],
  tasksEnabled: []
});

const getFunctionDescription = (name) => {
  const functionData = agentFunctions.value.find(f => f.name === name);
  return functionData ? functionData.description : '';
}

const getTasksDescription = (name) => {
  const functionData = agentFunctions.value.find(f => f.name === name);
  return functionData ? functionData.description : '';
}

const agentFunctionDescription = (item) => ({
  title: item.name,
  subtitle: item.description
})

const fetchClinics = async () => {
  try {
    const snapshot = await db.collection('clinics').get();

    const clinicsWithSubcollections = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const clinicData = {
          id: doc.id,
          ...doc.data()
        };
        const subcollectionSnapshot = await doc.ref.collection('agentSummaryPromptVersions').orderBy('dateCreated', 'desc').get();
        const agentSummaryPrompts = subcollectionSnapshot.docs.map(subdoc => ({
          id: subdoc.id,
          ...subdoc.data()
        }));

        return {
          ...clinicData,
          agentSummaryPrompts
        };
      })
    );

    clinics.value = clinicsWithSubcollections;

  } catch (error) {
    console.error('Error fetching clinics:', error);
  }
};

const resetClinic = () => {
  newClinic.value = {
    name: '',
    address: '',
    phoneNumbers: '',
    forwardsToNumber: '',
    seats: 0,
    admins: '',
    clinicians: '',
    domains: '',
    agentEnabled: false,
    recordingEnabled: false,
    agentPrompt: '',
    agentGreeting: '',
    requestTypes: '',
    agentSummaryPrompt: '',
    functionsEnabled: [],
    tasksEnabled: []
  };
}

const editClinic = () => {
  openClinicModal.value = true;
  editing.value = true;
};


const closeWithoutSaving = () => {
  openClinicModal.value = false;
  editing.value = false;
  openPrompt.value = false;
};

const closeAndSave = async () => {
  if (editing.value) {
    await updateClinic();
    console.log('clinic updated');
  } else {
    await saveClinic();
    console.log('clinic created');
  }
  openClinicModal.value = false;
  editing.value = false;
};

const handleRollback = async () => {
  if (selectedVersion.value === 'current') return;

  loadingAction.value = true;
  try {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
      ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
      : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    await axios.post(
      `${baseUrl}/rollbackAgentPrompt`,
      {
        clinicId: selectedClinic.value.id,
        versionId: selectedVersion.value
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );

    // Refresh clinic data and versions
    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchPromptVersions(selectedClinic.value.id);

  } catch (error) {
    console.error('Error rolling back version:', error);
  } finally {
    loadingAction.value = false;
  }
};

const handleSaveNewVersion = async () => {
  if (!isEditing.value || selectedVersion.value !== 'current') return;

  loadingAction.value = true;
  try {

    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
      ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
      : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    await axios.post(
      `${baseUrl}/updateAgentPrompt`,
      {
        clinicId: selectedClinic.value.id,
        newPrompt: displayedPrompt.value
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );

    // Refresh clinic data and versions
    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchPromptVersions(selectedClinic.value.id);

    // Reset editing state
    isEditing.value = false;
  } catch (error) {
    console.error('Error saving new version:', error);
  } finally {
    loadingAction.value = false;
  }
};


const saveClinic = async () => {
  try {
    const { phoneNumbers, admins, clinicians, domains, forwardsToNumber, ...clinic } = newClinic.value;
    const phoneNumbersArray = phoneNumbers?.split(',');
    const forwardsToNumberArray = forwardsToNumber?.split(','); // Convert to array

    const adminsArray = admins.split(',');
    const cliniciansArray = clinicians.split(',');
    const domainsArray = domains.split(',');
    await db.collection('clinics').add({
      phoneNumbers: phoneNumbersArray,
      admins: adminsArray,
      forwardsToNumber: forwardsToNumberArray,
      clinicians: cliniciansArray,
      domains: domainsArray,
      functionsEnabled: clinic.functionsEnabled || [],
      tasksEnabled: clinic.tasksEnabled || [],
      ...clinic
    });
    await fetchClinics();
    resetClinic();
  } catch (error) {
    console.error('Error saving clinic:', error);
  }
};

const deleteClinic = async (id) => {
  try {
    console.log('deleting clinic:', id);
    await db.collection('clinics').doc(id).delete();
    await fetchClinics();
    selectedClinic.value = null;
    showDeleteClinic.value = false;
  } catch (error) {
    console.error('Error deleting clinic:', error);
  }
};

const getArrayOfItems = (items) => {
  if (!items) return null
  return typeof items === 'string' ? items.split(',') : Object.values(items);
}

const updateClinic = async () => {
  try {
    const { phoneNumbers, admins, clinicians, domains, forwardsToNumber, ...clinic } = newClinic.value;
    const phoneNumbersArray = getArrayOfItems(phoneNumbers);
    const forwardsToNumberArray = getArrayOfItems(forwardsToNumber);
    const adminsArray = getArrayOfItems(admins);
    const cliniciansArray = getArrayOfItems(clinicians);
    const domainsArray = getArrayOfItems(domains);

    await db.collection('clinics').doc(selectedClinic.value.id).update({
      phoneNumbers: phoneNumbersArray,
      admins: adminsArray,
      forwardsToNumber: forwardsToNumberArray,
      clinicians: cliniciansArray,
      domains: domainsArray,
      functionsEnabled: clinic.functionsEnabled || [],
      tasksEnabled: clinic.tasksEnabled || [],
      ...clinic
    });

    await fetchClinics();
    resetClinic();
  } catch (error) {
    console.error('Error updating clinic:', error);
  }
};

const formatPrompt = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  let text = tmp.textContent || tmp.innerText || '';
  text = text.replace(/^\s+/gm, '\n').replace(/\.\s+(?=[A-Z])/g, '.\n\n');
  return text;
}

watch(() => editing.value, (isEditing) => {
  if (isEditing) {
    const { agentPrompt, ...clinic } = selectedClinic.value;
    newClinic.value = {
      agentPrompt: (agentPrompt) ? formatPrompt(agentPrompt) : '',
      ...clinic
    }
    return;
  }

  resetClinic();
});


const fetchSummaryPromptVersions = async (clinicId) => {
  loadingSummaryVersions.value = true;
  try {
    const versionsSnapshot = await db
      .collection('clinics')
      .doc(clinicId)
      .collection('agentSummaryPromptVersions')
      .orderBy('dateCreated', 'desc')
      .get();

    const versions = versionsSnapshot.docs.map(doc => ({
      versionId: doc.id,
      ...doc.data()
    }));

    summaryPromptVersions.value = [
      {
        versionId: 'current',
        label: `Current Version (${selectedClinic.value.version || 'N/A'})`,
        summaryPrompt: selectedClinic.value.agentSummaryPrompt || '',
        isCurrent: true,
        version: selectedClinic.value.version
      },
      ...versions.map(version => ({
        versionId: version.versionId,
        label: `Version ${version.version} - ${new Date(version.dateCreated).toLocaleDateString()}`,
        summaryPrompt: version.agentSummaryPrompt,
        isCurrent: false,
        version: version.version
      }))
    ];

    selectedSummaryVersion.value = 'current';
    displayedSummaryPrompt.value = selectedClinic.value.agentSummaryPrompt || '';
  } catch (error) {
    console.error('Error fetching summary prompt versions:', error);
  } finally {
    loadingSummaryVersions.value = false;
  }
};

const handleSummaryVersionChange = (versionId) => {
  const version = summaryPromptVersions.value.find(v => v.versionId === versionId);
  if (version) {
    displayedSummaryPrompt.value = version.summaryPrompt;
  }
};

const handleSaveSummaryVersion = async () => {
  if (!isEditingSummaryPrompt.value || selectedSummaryVersion.value !== 'current') return;

  loadingSummaryAction.value = true;
  try {
    const clinicRef = db.collection('clinics').doc(selectedClinic.value.id);
    const versionsRef = clinicRef.collection('agentSummaryPromptVersions');
    const currentVersion = selectedClinic.value.version || 0;
    const newVersion = currentVersion + 1;

    await versionsRef.add({
      version: newVersion,
      dateCreated: new Date().toISOString(),
      agentSummaryPrompt: displayedSummaryPrompt.value
    });

    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchSummaryPromptVersions(selectedClinic.value.id);

    isEditingSummaryPrompt.value = false;
  } catch (error) {
    console.error('Error saving new summary version:', error);
  } finally {
    loadingSummaryAction.value = false;
  }
};

const handleSummaryRollback = async () => {
  if (selectedSummaryVersion.value === 'current') return;

  loadingSummaryAction.value = true;
  try {
    const version = summaryPromptVersions.value.find(v => v.versionId === selectedSummaryVersion.value);
    if (!version) return;
    console.log('rolling back to version:', version);
    const clinicRef = db.collection('clinics').doc(selectedClinic.value.id);

    await clinicRef.update({
      agentSummaryPrompt: version.summaryPrompt,
      version: version.version
    });

    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchSummaryPromptVersions(selectedClinic.value.id);

  } catch (error) {
    console.error('Error rolling back summary version:', error);
  } finally {
    loadingSummaryAction.value = false;
  }
};

const openSummaryPromptDialog = async () => {
  if (selectedClinic.value) {
    openSummaryPrompt.value = true;
    await fetchSummaryPromptVersions(selectedClinic.value.id);
  }
};

const closeSummaryPromptDialog = () => {
  openSummaryPrompt.value = false;
  selectedSummaryVersion.value = null;
  summaryPromptVersions.value = [];
  displayedSummaryPrompt.value = '';
  isEditingSummaryPrompt.value = false;
};

const selectClinic = (clinic) => {
  let prompt = clinic.agentPrompt;
  if (prompt) {
    prompt = formatPrompt(prompt);
  }
  selectedClinic.value = {
    ...clinic,
    agentPrompt: prompt,
    agentSummaryPrompt: clinic.agentSummaryPrompt || '',
    version: clinic.version || 0
  };
};

watch(openSummaryPrompt, async (isOpen) => {
  if (isOpen && selectedClinic.value) {
    await fetchSummaryPromptVersions(selectedClinic.value.id);
  }
});

onMounted(async () => {
  await fetchClinics();
  await fetchAgentFunctions()
});


// Function to fetch versions from the Firebase function
const fetchPromptVersions = async (clinicId) => {
  loadingVersions.value = true;
  try {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
      ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
      : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    const response = await axios.get(
      `${baseUrl}/getAgentPromptVersions?clinicId=${clinicId}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    const versions = response.data.versions;

    promptVersions.value = [
      {
        versionId: 'current',
        label: `Current Version (${selectedClinic.value.versionNumber || 'N/A'})`,
        agentPrompt: selectedClinic.value.agentPrompt || '',
        isCurrent: true
      },
      ...versions.map(version => ({
        versionId: version.versionId,
        label: `Version ${version.versionNumber} - ${new Date(version.timestamp).toLocaleDateString()}`,
        agentPrompt: version.agentPrompt,
        isCurrent: false
      }))
    ];

    selectedVersion.value = 'current';
    displayedPrompt.value = selectedClinic.value.agentPrompt || '';
  } catch (error) {
    console.error('Error fetching prompt versions:', error);
  } finally {
    loadingVersions.value = false;
  }
};

// Handle version selection change
const handleVersionChange = (versionId) => {
  const version = promptVersions.value.find(v => v.versionId === versionId);
  if (version) {
    displayedPrompt.value = version.agentPrompt;
  }
};

const closePromptDialog = () => {
  openPrompt.value = false;
  selectedVersion.value = null;
  promptVersions.value = [];
  displayedPrompt.value = '';
  isEditing.value = false; // Reset editing state
};

// Update the existing watch for openPrompt
watch(openPrompt, async (isOpen) => {
  if (isOpen && selectedClinic.value) {
    await fetchPromptVersions(selectedClinic.value.id);
  }
});


</script>

<style scoped>
.clinics-view {
  padding: 16px;
}

.selected-row {
  background-color: rgb(var(--v-theme-primary-lighten-5));
}

.clinic-details :deep(td) {
  height: auto;
  padding: 12px;
}

.clinic-details :deep(.v-table) {
  background: transparent;
}

.clinic-details :deep(.v-chip-group) {
  margin: 0;
}

td {
  text-align: left;
}

.clinic-item {
  cursor: pointer;
}

.add-clinic {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.clinic-details-title {
  display: flex;
  justify-content: left;
  align-items: left;
}
</style>