// firebase.js
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import "firebase/compat/auth";

//Production
const firebaseConfig = {
  // apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  // appId: process.env.VUE_APP_APP_ID,

};


firebase.initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
const auth = firebase.auth();


const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};


const db = firebase.firestore();

export { db, auth, signInWithGoogle };
